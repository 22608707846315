//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixin as clickaway } from 'vue-clickaway'
import { Events as TaskEvents, TaskEventBus } from '@/components/Tasks/EventBus'
import { mapGetters } from 'vuex'
import {
  tagsStore,
  contactsStore,
  teamsStore,
  uiStore,
  uiSettingsStore,
} from '@/store'

export default {
  name: 'TaskDeskControls',

  components: {
    'custom-filter': () => import('./TaskDeskCustomFilter'),
    IconPlusCircle: () => import('@/components/UI/icons/IconPlusCircle'),
  },

  mixins: [clickaway],

  data () {
    return {
      isLoading: false,
      forcedFilter: null,
      showAssigneeSelector: false,
      showOwnerSelector: false,
      showTagSelector: false,
      taskDeskCompactCardsOptions: [
        { icon: 'fas fa-th', value: true },
        { icon: 'fas fa-th-large', value: false },
      ],
    }
  },

  mounted () {
    TaskEventBus.$on(TaskEvents.TASK_DESK_LOADING, this.onIsLoading)
  },

  beforeDestroy () {
    TaskEventBus.$off(TaskEvents.TASK_DESK_LOADING, this.onIsLoading)
  },

  methods: {
    toggleCompactCardsView (bool) {
      this.sendGoal(bool ? 'ВКЛ компактный режим' : 'ВКЛ расширенный режим')

      uiStore.actions.setTaskDeskCompactView(bool)
    },
    toggleLeftColumn () {
      this.sendGoal(this.leftColumnHidden ? 'Показать список чатов' : 'Скрыть список чатов')

      uiStore.actions.toggleLeftColumn(!this.leftColumnHidden)
    },
    // closeAssigneeSelector () {
    //   this.showAssigneeSelector = false
    // },
    // closeOwnerSelector () {
    //   this.showOwnerSelector = false
    // },
    // closeTagSelector () {
    //   this.showTagSelector = false
    // },
    // optionsTemporary () {
    //   const options = [
    //     {
    //       key: 'appearance',
    //       name: this.$t('chatlist.appearance'),
    //       variants: [
    //         this.$t('chatlist.appearanceOptions.compact'),
    //         this.$t('chatlist.appearanceOptions.extended')
    //       ],
    //       selectedIndex: +(!this.compactList),
    //       action: (index) => {
    //         this.$store.dispatch('SET_TASK_DESK_COMPACT_VIEW', index === 0)
    //       }
    //     }
    //   ]

    //   return options
    // },
    // options () {
    //   // status, assignee, owner, section, tag
    //   let options = [{
    //     name: this.statusWord,
    //     action: () => { this.forcedFilter = 'status' }
    //   }]

    //   this.currentDeskFilterKey !== 'assignee' && options.push({
    //     name: this.assigneeWord,
    //     action: () => { this.forcedFilter = 'assignee' }
    //   })

    //   this.currentDeskFilterKey !== 'owner' && options.push({
    //     name: this.ownerWord,
    //     action: () => { this.forcedFilter = 'owner' }
    //   })

    //   this.currentDeskFilterKey !== 'section' && this.currentDeskFilterKey !== null && options.push({
    //     name: this.projectWord,
    //     action: () => { this.forcedFilter = 'section' }
    //   })

    //   this.currentDeskFilterKey !== 'tag' && options.push({
    //     name: this.tagWord,
    //     action: () => { this.forcedFilter = 'tag' }
    //   })

    //   options.push({ divider: true })

    //   options.push({
    //     name: this.allTasks,
    //     action: () => this.setFilter({ status: ['new', 'done'] })
    //   })

    //   options.push({
    //     name: this.$t('dashboard.filterOptions.inbox'),
    //     action: () => this.setFilter({ assignee: [this.$store.getters.profile.jid] })
    //   })

    //   options.push({
    //     name: this.$t('dashboard.filterOptions.outbox'),
    //     action: () => this.setFilter({ owner: [this.$store.getters.profile.jid] })
    //   })

    //   return options
    // },
    // statusOptions () {
    //   let index = 0
    //   if (this.filters.status && this.filters.status.length === 2) index = 2
    //   if (this.filters.status && this.filters.status[0] === 'done') index = 1

    //   return [{
    //     key: 'task-desk-status',
    //     variants: [this.activeWord, this.doneWord, this.allTasks],
    //     selectedIndex: index,
    //     action: (index) => {
    //       index === 0 && this.setFilter({ status: ['new'] })
    //       index === 1 && this.setFilter({ status: ['done'] })
    //       index === 2 && this.setFilter({ status: ['new', 'done'] })
    //     }
    //   }]
    // },
    // projectOptions () {
    //   const options = [
    //     {
    //       key: 'none',
    //       name: 'Без проекта',
    //       action: () => {
    //         // this.task.section = null
    //       }
    //     },
    //     { divider: true }
    //   ]

    //   for (const uid of Object.keys(this.$store.getters.sections('task'))) {
    //     const s = this.$store.getters.section(uid, 'task')
    //     s.isArchive || options.push({
    //       key: uid,
    //       name: s.name,
    //       // active: this.task.section === uid,
    //       action: () => {
    //         this.setFilter({ section: uid })
    //       }
    //     })
    //   }

    //   return options
    // },
    setFilter (filter) {
      this.forcedFilter = null

      const newFilters = Object.assign({}, this.currentDeskFilters, filter)
      uiStore.actions.taskDeskSetFilters(newFilters)
    },
    onIsLoading (v) {
      this.isLoading = v
    },
    groupBy (value) {
      switch (value) {
        case 'status':
          this.sendGoal('ВКЛ «Группировка по Статусу»')
          break
        case 'section':
          this.sendGoal('ВКЛ «Группировка по Проекту»')
          break
        case 'assignee':
          this.sendGoal('ВКЛ «Группировка по Исполнителю»')
          break
        case 'owner':
          this.sendGoal('ВКЛ «Группировка по Постановщику»')
          break
        case 'tag':
          this.sendGoal('ВКЛ «Группировка по Тегу»')
          break
        case 'importance':
          this.sendGoal('ВКЛ «Группировка по Приоритету»')
          break
      }

      uiSettingsStore.actions.setTabFilter(value)
    },
    sortBy (value) {
      switch (value) {
        case 'activity':
          this.sendGoal('ВКЛ «Сортировка по активности»')
          break
        case 'deadline':
          this.sendGoal('ВКЛ «Сортировка по дедлайну»')
          break
        case 'new':
          this.sendGoal('ВКЛ «Сортировка Сначала новые»')
          break
        case 'old':
          this.sendGoal('ВКЛ «Сортировка Сначала старые»')
          break
        case 'importance':
          this.sendGoal('ВКЛ «Сортировка по Приоритету»')
          break
      }

      uiSettingsStore.actions.setTaskDeskTabSorting(value)
    },
    /**
     * Open modal window for create task
     */
    showNewTaskModel () {
      uiStore.actions.showModal({ instance: 'new-task', payload: null })
      window.goal('taskControls', { taskControls: 'Создать задачу − «Плюс» на доске задач' })
    },
    sendGoal (msg) {
      window.goal('dashboardControls', { dashboardControls: msg })
    },
  },
  computed: {
    ...mapGetters([
      'profileId',
      'profile',
    ]),
    currentDeskSortingKey () {
      return uiSettingsStore.getters.currentDeskSortingKey
    },
    currentDeskFilterKey () {
      return uiSettingsStore.getters.currentDeskFilterKey
    },
    taskDeskCompactCards () {
      return uiStore.getters.taskDeskCompactCards
    },
    currentDeskFilters () {
      return uiStore.getters.currentDeskFilters
    },
    leftColumnHidden () {
      return uiStore.getters.leftColumnHidden
    },
    menuOptions () {
      const options = [{
        label: this.$t('modals.TasksColorsRules.buttonTitle'),
        action: () => {
          this.sendGoal('ВКЛ «Правила оформления»')
          uiStore.actions.showModal({ instance: 'TasksColorsRules' })
        },
      }, {
        label: this.$t('modals.ExportModal.title'),
        action: () => {
          this.sendGoal('ВКЛ «Экспорт задач»')
          uiStore.actions.showModal({ instance: 'ExportModal' })
        },
      }]

      if (this.profile.canImportTasks) {
        options.push({
          label: this.$t('modals.ImportTasks.title'),
          action: () => {
            this.sendGoal('ВКЛ «Импорт задач»')
            uiStore.actions.showModal({ instance: 'ImportTasks' })
          },
        })
      }

      return options
    },
    canCreateTask () {
      return contactsStore.getters.contact(this.profileId)?.canCreateTask ?? false
    },
    groupByCurrentLabel () {
      const selectedValue = this.currentDeskFilterKey
      const options = this.groupByOptions
      const selectedLabel = options.find(({ value }) => value === selectedValue)
      return selectedLabel
    },
    groupByOptions () {
      const options = [
        {
          value: 'status',
          label: this.$t('dashboard.viewOptions.byStatus'),
        },
        {
          value: 'section',
          label: this.$t('dashboard.viewOptions.byProject'),
        },
        {
          value: 'assignee',
          label: this.$t('dashboard.viewOptions.byAssignee'),
        },
        {
          value: 'owner',
          label: this.$t('dashboard.viewOptions.byOwner'),
        },
        {
          value: 'tag',
          label: this.$t('dashboard.viewOptions.byTag'),
        },
      ]

      // add grouping by importance only if it's enabled on current team
      teamsStore.getters.currentTeam.useTaskImportance && options.push({
        value: 'importance',
        label: this.$t('dashboard.viewOptions.byImportance'),
      })

      return options
    },
    sortByCurrentLabel () {
      const selectedValue = this.currentDeskSortingKey
      const options = this.sortByOptions
      const selectedLabel = options.find(({ value }) => value === selectedValue)
      return selectedLabel
    },
    sortByOptions () {
      return [
        {
          value: 'activity',
          label: this.$t('dashboard.sortingOptions.byActivity'),
        },
        {
          value: 'deadline',
          label: this.$t('dashboard.sortingOptions.byDeadline'),
        },
        {
          value: 'new',
          label: this.$t('dashboard.sortingOptions.newFirst'),
        },
        {
          value: 'old',
          label: this.$t('dashboard.sortingOptions.oldFirst'),
        },
        {
          value: 'importance',
          label: this.$t('dashboard.sortingOptions.byImportance'),
        },
      ]
    },
    projectWord () {
      return this.$t('dashboard.viewOptions.byProject')
    },
    statusWord () {
      return this.$t('dashboard.filterOptions.status')
    },
    activeWord () {
      return this.$t('dashboard.filterOptions.onlyActive')
    },
    doneWord () {
      return this.$t('dashboard.filterOptions.onlyDone')
    },
    allTasks () {
      return this.$t('dashboard.filterOptions.allTasks')
    },
    assigneeWord () {
      return this.$t('dashboard.filterOptions.assignee')
    },
    assigneePluralWord () {
      return this.$t('dashboard.filterOptions.assignee_plural')
    },
    ownerWord () {
      return this.$t('dashboard.filterOptions.owner')
    },
    ownerPluralWord () {
      return this.$t('dashboard.filterOptions.owner_plural')
    },
    tagWord () {
      return this.$t('dashboard.filterOptions.tag')
    },
    tagPluralWord () {
      return this.$t('dashboard.filterOptions.tag_plural')
    },
    // statusFilterText () {
    //   if (!this.filters.status || this.filters.status.length === 0) return `${this.statusWord}: ${this.activeWord}`
    //   if (this.filters.status.length === 2) return `${this.statusWord}: ${this.allTasks}`
    //   if (this.filters.status[0] === 'new') return `${this.statusWord}: ${this.activeWord}`
    //   if (this.filters.status[0] === 'done') return `${this.statusWord}: ${this.doneWord}`
    //   return null
    // },
    // assigneeFilterText () {
    //   if (!this.filters.assignee || this.filters.assignee.length === 0) return this.assigneeWord
    //   if (this.filters.assignee.length === 1) return `${this.assigneeWord}: ${this.$store.getters.contactDisplayName(this.filters.assignee[0])}`

    //   return `${this.assigneePluralWord} (${this.filters.assignee.length})`
    // },
    // ownerFilterText () {
    //   if (!this.filters.owner || this.filters.owner.length === 0) return this.ownerWord
    //   if (this.filters.owner.length === 1) return `${this.ownerWord}: ${this.$store.getters.contactDisplayName(this.filters.owner[0])}`

    //   return `${this.ownerPluralWord} (${this.filters.owner.length})`
    // },
    // tagFilterText () {
    //   if (!this.filters.tag || this.filters.tag.length === 0) return this.tagPluralWord
    //   if (this.filters.tag.length === 1) return `${this.tagWord}: #${this.filters.tag[0]}`

    //   return `${this.tagPluralWord} (${this.filters.tag.length})`
    // },
    classHideChatListButton () {
      return [
        'fa',
        this.leftColumnHidden ? 'fa-angle-double-right' : 'fa-angle-double-left',
      ]
    },
    // sectionFilterName () {
    //   if (this.filters.section) {
    //     if (this.$store.getters.section(this.filters.section, 'task')) {
    //       return this.$store.getters.section(this.filters.section, 'task').name
    //     } else return this.projectWord
    //   } else return this.projectWord
    //   // return this.filters.section
    //   //   ? this.$store.getters.section(this.filters.section, 'task').name
    //   //   : this.projectWord
    // },
    // showSectionPopup () {
    //   if (this.forcedFilter === 'section') return true
    //   if (this.filters.section && this.filters.section.length > 0) return true
    //   return false
    // }
    // currentDeskSortingKey () {
    //   const { getters } = this.$store
    //   return getters.currentDeskSortingKey || (this.sortEntries[0] || {}).key
    // },
    // filters () {
    //   return this.currentTab.filters || []
    // },
    // sortEntries () {
    //   return this.currentTab.sort || []
    // },
    // currentTab () {
    //   return this.tabs[this.currentTabKey] || {}
    // },
    // currentTabKey () {
    //   const { getters } = this.$store
    //   return getters.currentDeskTabKey
    // },
    // tabs () {
    //   const { getters } = this.$store
    //   return getters.taskTabs || {}
    // }
  },

  watch: {
    currentDeskFilterKey (key) {
      if (key === 'owner' || key === 'assignee' || key === 'tag') {
        this.setFilter({ [key]: [] })
      } else if (key) {
        this.setFilter({ [key]: null })
      }
    },
  },
  async created () {
    // TODO: refactor: add a flag to the store to mark if tags were already loaded
    // TODO: backend - generate gentime for tags (or websocket event) to let user know that tags were updated
    tagsStore.state.tags.length <= 0 && tagsStore.actions.getTags()
  },
}
